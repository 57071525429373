import { Link, NavLink, Outlet, useFetcher, useNavigate } from '@remix-run/react';
import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import { routes } from '~/utils/routes';
import { useRootLoaderData } from '~/utils/useRootLoaderData';

import { BellIcon, CircleHelpIcon, ClockIcon, HomeIcon, SearchIcon, SettingsIcon } from 'lucide-react';

import Icon from '~/assets/icon-deliberate-time.jpg';
import { Avatar, AvatarFallback, AvatarImage } from '~/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { redirectToKey } from '~/types';

export default function Wrapper() {
  const user = useRootLoaderData()?.user;
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const handleLogout = () => {
    fetcher.submit(
      {},
      {
        action: `/logout?${redirectToKey}=${encodeURI(`${location.pathname}${location.search}`)}`,
        method: 'post',
      },
    );
  };
  return (
    <div className="flex flex-col">
      {user?.type === 'real' ? null : (
        <div className="bg-orange-400 p-2 text-center">
          <p className="text-sm">
            You're looking at fake data.{' '}
            <Link to={routes.register} className="underline" prefetch="intent">
              Create an account
            </Link>{' '}
            to see your own!
          </p>
        </div>
      )}
      <div className="flex flex-col min-h-screen">
        <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
          <div className="hidden border-r bg-gray-100/40 lg:block dark:bg-gray-800/40">
            <div className="flex h-full max-h-screen flex-col gap-2">
              <div className="flex h-[60px] items-center border-b px-6">
                <Link className="flex items-center gap-2 font-semibold" to={routes.home} prefetch="intent">
                  <img src={Icon} className="h-6 w-6" />
                  <span className="">Deliberate Time</span>
                </Link>
                <Button className="ml-auto h-8 w-8" size="icon" variant="outline">
                  <BellIcon className="h-4 w-4" />
                  <span className="sr-only">Toggle notifications</span>
                </Button>
              </div>
              <div className="flex-1 overflow-auto py-2">
                <nav className="grid items-start px-4 text-sm font-medium">
                  {[
                    { name: 'Dashboard', route: routes.home, Icon: HomeIcon },
                    { name: 'Settings', route: routes.settings, Icon: SettingsIcon },
                    { name: 'Help', route: routes.help, Icon: CircleHelpIcon },
                  ].map(item => (
                    <NavLink
                      key={item.route}
                      className={({ isActive }) =>
                        `flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                          isActive
                            ? 'bg-gray-100 text-gray-900 hover:text-gray-900 dark:bg-gray-800 dark:text-gray-50 dark:hover:text-gray-50'
                            : 'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50'
                        }`
                      }
                      to={item.route}
                      prefetch="intent"
                    >
                      <item.Icon className="h-4 w-4" />
                      {item.name}
                    </NavLink>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <header className="flex h-14 items-center gap-4 border-b bg-gray-100/40 px-6 dark:bg-gray-800/40">
              <Link className="lg:hidden" to={routes.home}>
                <ClockIcon className="h-6 w-6" />
                <span className="sr-only">Home</span>
              </Link>
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500 dark:text-gray-400" />
                    <Input
                      className="w-full bg-white shadow-none appearance-none pl-8 md:w-2/3 lg:w-1/3 dark:bg-gray-950"
                      placeholder="Search"
                      type="search"
                    />
                  </div>
                </form>
              </div>
              {user?.type === 'real' ? (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      className="rounded-full border border-gray-200 w-8 h-8 dark:border-gray-800"
                      size="icon"
                      variant="ghost"
                    >
                      <Avatar>
                        <AvatarImage src={undefined} alt="Avatar" />
                        <AvatarFallback>{(user?.name?.[0] ?? user?.email?.[0])?.toUpperCase()}</AvatarFallback>
                      </Avatar>
                      <span className="sr-only">Toggle user menu</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>My Account</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => navigate(routes.settings)}>Settings</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => navigate(routes.help)}>Support</DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : (
                <Link to={routes.register} prefetch="intent">
                  <p className="text-gray-500 dark:text-gray-400">Sign Up</p>
                </Link>
              )}
            </header>
            <main className="flex-1 bg-gray-100 dark:bg-gray-800 p-6 md:p-10">
              <Outlet />
            </main>
          </div>
        </div>
        <footer className="bg-gray-100 dark:bg-gray-800 px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
          <div className="max-w-6xl mx-auto flex items-center justify-between">
            <p>© 2024 Deliberate Time</p>
            <p>
              Made by{' '}
              <Link to="https://willsmithte.com" className="underline">
                Will Smith
              </Link>
            </p>
            <nav className="flex items-center gap-4">
              <Link className="hover:text-gray-900 dark:hover:text-gray-50" to={routes.privacy} prefetch="intent">
                Privacy
              </Link>
              <Link className="hover:text-gray-900 dark:hover:text-gray-50" to={routes.terms} prefetch="intent">
                Terms
              </Link>
              <Link className="hover:text-gray-900 dark:hover:text-gray-50" to={routes.contact}>
                Contact
              </Link>
            </nav>
          </div>
        </footer>
      </div>
    </div>
  );
}
